.site-header{
   .inner{
      @include container;
      position: relative;

      display:grid;
      grid-template-columns: minmax(100px,310px) 1fr;
      grid-template-rows:  1fr 1fr;
      grid-template-areas: "branding contact" "branding nav";
      align-items: start;
   }

   .logo{
      grid-area: branding;
      vertical-align: bottom;
      padding-top: $size__spacing/4;
   }

   .contact{
      grid-area: contact;
      font-size: rem(15px);
      text-align: right;
      grid-column: 2/3;
      align-self: end;
      a {
         background-color: $color__brand-white;
         padding: 3px;
      }
      a+a{
         margin-left: rem(20px);
      }
   }

   .toggable {
      grid-area: nav;
   }

   .header-button {
      text-align: center;
      grid-area: button
   }

   .main-navigation{
      text-align: right;
      margin-top: rem(10px);

      a+a{
         margin-left: rem(30px);
      }

      ul ul{
         text-align: left;
         min-width: 200px;
         a+a {
            margin-left: 0;
         }
      }
   }

   .mobile{
      display: none;
   }

   &.white{
      a{
         color: #fff;
      }
   }

   body.scrolled &{
      background-color: rgba(251,251,251,1);

      .logo{
         max-width: 3em;
         svg {
           max-height: 3rem;
         }
      }
  }

  body.white &{
      color:#fff;
  }
}