.site-header{
    background-color: rgba(#fff,0.5);

    .inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap-reverse;
    }

    .logo {
        z-index: 990;
        width: 100%;
        margin-top: 0.5rem;
        margin-left: $size__content-margin;
        max-width: 30vw;
        margin-right: 20px;
    }

   .toggable{

        height: 0;
        width: 100vw;
        top: 100vw;
        overflow: hidden;
        position: absolute;
        opacity: 0;
        transition: opacity 0.5s;
        transition-delay: 700ms;

        > * {
            overflow: hidden;
            opacity: 0;
            transition:all 0.5s;
            transform: translateY(100%);
            transition-delay: 0ms;
        }
    }

    &.toggled .toggable{
        top: 0;
        height: 100vh;
        opacity: 1;
        background-color: rgba(#fff,0.99);
        padding: $spacing $size__content-margin;
        overflow: scroll;
        transition-delay: 0ms;

        > *{
            transition-delay: 700ms;
            opacity: 1;
            transform:translateY(0);
        }
    }

    .contact {
        margin-top: 1rem;
        a {
            display: block;
            margin-bottom: 1rem;
        }
    }

    .header-button {
        @include round-button;

        right: 2rem;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    nav{
        margin-top: 3em;
        .mobile{
            color: $color__link-alternative;
            display: block !important;
        }
        li {
            display: block;
            &+li a{
                margin-left: 0;
            }
        }
        a:not(.locale){
            display: block;
        }
        a.locale {
            margin-top: 20px;
            margin-right: 20px;
        }
    }
    .contact {
        display: none;

    }
}