%container {
  max-width: $size__container;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: $size__container){
      padding-left: 2rem;
      padding-right: 2rem;
    }
}

%contact-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        display: flex;
        span,strong {
            font-weight: 300;
            margin-right: 1rem;
        }
    }
}

%input{
    padding: 0.5em;
    display: block;
    // width: 100%;
    border: 1px solid $color__border-input;
    transition: all 1s;
    margin-top: 1rem;

    &:focus,&:active {
      border-bottom: $color__border-input-focus 4px solid;
      outline: none;
      // box-shadow: $box__shadow-default
    }
}

%button-base{
   display: inline-block;
   min-height: rem(30px);
   text-transform: uppercase;
   font-weight: bold;
   letter-spacing: 0.08em;
   text-decoration: none;
   line-height: 1;
   font-size: rem(16px);
   padding: rem(14px) rem(38px);
   border: none;
}


%box-base {
  background-color: $color__brand-white;
  border: 1px solid $color__brand-darkgray;
  padding: 2rem;
  position: relative;
}

%underline {
	position: relative;
	margin-bottom: $size__spacing/2;
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    left: 50%;
    margin-left: -2rem;
    margin-top: 40px;
    bottom: -1.45rem;
    width: 4rem;
    height: 3px;
    background-color: $color__brand-primary;
    border-radius: 2px;
  }
}