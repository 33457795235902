body {
	background-size: 30%;
}

.value + .value,.icon-box ,.grid__content + .grid__content,.fat-link, .grid-sidebar div+*,footer.site-footer .flex-container>div + div ,.office+.office{
	margin-top: $size__mobile-spacing;
}

.single-post,.footer-creative-commons ,.fat-link{
	margin-bottom: $size__mobile-spacing;
}

.reviews {

	.review {
		width: calc(100vw - 4rem);
		display: inline-block;

		& + .review {
		 	// margin-left: 2rem;
		}

	}

}

.flex-container {
	flex-direction: column;
}

.static-content .overflow figure {
	float: none;

}
.services {
	padding-bottom: 0;
}
.services-list {
	background-size: 50%;
}
.map-wrapper {
	iframe {
		position: relative;
	}
	.contact-form {
		margin: 0;
		box-shadow: none;
	}
	.flex {
		flex-direction: column;
	}
	.flex p+p {
		margin-left: 0;
	}
}

.service {
	figure,h4 {
		margin-bottom: 2rem;
	}
}

blockquote.testimonial {
	margin-left: 0;
	margin-left: 0;
	p {
		font-size: rem(20px)
	}
}
.arrows {
	padding-left: 3rem;
}
.arrow {
	width: 15px;
	margin-right: 1rem;
	svg {
		max-width: 100%;
	}
}

.icon-box {
	figure {
		img {
			width: 110px;
		}
	}
}