.readmore {
    display: inline-flex;
    align-items: center;
    color: $color__brand-primary;
    font-weight: bold;
    transition: all 0.5s;

    span {
        width: 2rem;
        transition:all 0.5s;
        height: 3px;
        background-color: $color__brand-secondary;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        border-radius: 2px;
    }

    &:hover {
        padding-right: 3rem;
        span {
            width: 0;
            margin: 0;
            margin-right: 0.5rem;
        }
    }

    .text-center &:hover {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

a.button,[type='submit'],blockquote p a{
    @extend %button-base;
    position: relative;
    z-index: 5;
    // background-color: $color__brand-primary;
    background-color: $color__brand-secondary;
    color: $color__brand-white;
    transition:all 1s;
    cursor: pointer;
    font-weight: 900;
    // box-shadow: 0 0 0 #fff;
    &:hover,&:active,&:focus{
        // background-color: darken($color__brand-primary,10%);
        // box-shadow: $box__shadow-default;
        background-color: darken($color__brand-secondary,10%);
        box-shadow: $box__shadow-default;
   }

    .site-header &{
        background-color: $color__brand-primary;
    }
    .site-footer & {
        background-color: $color__brand-white;
        color: $color__brand-secondary;
        border-color: $color__brand-white;
    }
}

.shadow-button {
    background:  url(../images/button-shadow.svg) 0 0 no-repeat;
    background-size: contain;
    display: inline-block;
    padding: 1rem;
    color: #fff;
    padding-bottom: 2rem;
    font-weight: bold;
    line-height: 1;
    font-size: rem(30px);
    min-width: rem(180px);
    min-height: rem(162px);
    transition: all 0.6s;
    position: relative;
    top: 0;
    &:hover {
        top: -0.5rem;
    }

    @media screen and (max-width: $size__mobile) {
        margin-left: auto;
        margin-right: auto;
    }

}

a.add-button,footer button{
    @extend %button-base;
    color: $color__brand-primary;
}

footer button {
    background-color: $color__brand-primary;
    color: #fff;
}

.close {
    border: none;
    background-color: transparent;
    float: right;
}

.button-small {
    border: 3px solid $color__brand-primary;
    background-color: $color__brand-primary;
    color: $color__brand-white;
    display: inline-block;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 0.75rem;
    padding: 3px 10px;
    min-height: 30px;
}

.button-delete {
    background-color: red;
    border-color: red;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: $size__spacing/4;
}

.header-button {
    background-color: $color__brand-secondary;
    color: $color__brand-white;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.11em;
    padding: rem(10px) rem(20px);
    line-height: 1;
    vertical-align: middle;

}

.menu-toggle {
    @include round-button($color__brand-primary);
    z-index: 999;
    bottom: 2rem;
    right: 2rem;

    span,&::before,&::after{
        display: inline-block;
        height: rem(3px);
        min-height: 3px;
        width: 2rem;
        background-color: currentColor;
        vertical-align: middle;
        transition: all .2s ease-in-out;
        transform-origin:center;
        border-radius: 2px;
    }

    &::before,&::after{
        content: '';
        position: absolute;
        // right: 0;
    }
    span{
         // top: 50%;
         margin-top: -1px;
    }
    &::before{
         top: 32%;
    }
    &::after{
         bottom: 33%;
         margin-top: -2px;
    }


   .toggled & {
        span{
            opacity: 0;
        }
        &::before{
            top: 44%;
            transform: rotate(45deg);
        }
        &::after{
            bottom: 49%;
            transform: rotate(-45deg);
        }
    }

    @media screen and (min-width: $size__mobile){
        display: none;
    }
}