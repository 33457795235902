.posts {

	.single-post {
		position: relative;
		padding-bottom: 2rem;
		margin-right: 14.29%;

		.post-title {
			color: $color__brand-primary;
		}

		figure {
			display: flex;
			object-fit: contain;
			overflow: hidden;
			margin-left: 14.29%;
			img {
				width: 100%;
			}
		}

		article {
			background-color: #ededed;
			padding: 2rem 1rem;
			position: relative;
			top: rem(-77px);
			margin-right: 14.29%;

			.post-title {
				margin-top: 0;
			}
			a.readmore {
				text-align: right;
				display: block;

			}
		}

		.meta {
			text-transform: uppercase;
			margin-bottom: 1rem;
		}
	}
}

.blog-meta {
	color: $color__brand-darkgray;
	font-weight: bold;
	text-transform: uppercase;
	margin-top: $size__spacing/2;
	font-size: rem(11px);

	ul {
		margin: 0;
		padding: 0;
		display: inline-block;
		font-size: inherit;
		vertical-align: baseline;

	}

	li {
		font-size: inherit;
		display: inline-block;
	}

	a {
		text-decoration: none;
		font-size: inherit;
		vertical-align: baseline;
		color: inherit;
		font-weight: bold;

	}
}
.widget {
	&+.widget {
		margin-top: 3rem;
	}
	.widget-title {
		font-weight: bold;
		font-size: rem(15px);
		text-transform: uppercase;
		border-left: 3px solid $color__brand-secondary;
		padding-left: 2rem;
		padding-top: 0;
		padding-bottom: 0;
		line-height: 1.5;
		color: $color__brand-primary;
		margin-bottom: 1rem;
	}

	ul li +li {
		margin-top: 1rem;
	}

	.latest-posts {
		list-style: none;
		margin: 0;padding: 0;
		margin-left: 2rem;
		border-left: 2px solid #fff;
		color: #575756;

		a {
			font-weight: bold;
		}

		p {
			margin-top: 0;
			font-size: rem(11px);
			text-transform: uppercase;

		}
	}
}
.blog-footer {
	.single-post {
		div {
			line-height: 1;
		}
	}
}