$baseFontSize: 18 !default;

$transitionDuration: .3s !default;
$transitionTimingFunction: cubic-bezier(.19, 1, .22, 1) !default;

$zoomIconBackground: rgba($color__brand-primary, .94) !default;
$zoomIconColor: #fff !default;

$lightboxBackground: rgba($color__brand-secondary, .94) !default;

$figcaptionBackground: rgba(255, 255, 255, .94) !default;
$figcaptionColor: #1a2a3a !default;

$counterBackground: transparent !default;
$counterColor: #fff !default;

$buttonBackground: transparent !default;
$buttonColor: #fff !default;

$loaderColor: #fff !default;

$slideMaxHeight: 85vh !default;
$slideMaxWidth: 85vw !default;