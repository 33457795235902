@import 'forms/fields';
@import 'forms/buttons';

.contact-form {
    padding:0 $size__spacing/2;
    position: relative;
    z-index: 2;

    @media screen and (max-width: $size__container) {
      padding-left: 0;
      padding-right: 0;
      margin-top: $size__spacing/2;
    }

}

.fieldset{
    border: none;
    // margin: 0;
    padding: 0;

    label {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    &.submit {
      margin-top: 2rem;
      text-align: right;
    }

    &.checkbox {
      p {
        position: relative;
      }

      input {
        position: absolute;
        z-index: -1;
        top: 2px;
        left: -2px;

        &:checked + label:before {
          background-color: $color__brand-secondary
        }

        &[type='radio'] + label:before {
          border-radius: 50%;
          top: 4px;
          left: 1px;
        }
      }

      label {
          text-transform: none;
          font-size: 0.9rem;
          position: relative;
          display: flex;
          align-items: center;
          z-index: 1;
          padding-left: 1.9rem;

          a {
            margin:0 0.3rem  ;
          }

          &:before {
            background-color: $color__brand-white;
            display: inline-block;
            vertical-align: middle;
            margin-right: 0.5rem;
            width: 0.8rem;
            height: 0.8rem;
            min-width: 15px;
            min-height: 15px;
            content: '';
            border: 1px solid $color__brand-darkgray;
            left: 0;
            position: absolute;
            top: 2px;
            transition: background-color 0.5s;
          }
      }
    }
}

.alert{
  @extend %box-base;
  padding: 1rem;
  margin-bottom: $size__spacing/2;
  &.alert-success {
     background-color: $color__brand-primary;
     color: $color__brand-white;
  }


  p {
    margin: 0;
    text-align: center;

  }
}


.privacy-policy {
  display: flex;
  p {
    margin: 0;
    margin-left: 1rem;
    font-size: 0.8rem;
    margin-top: -5px;
  }
}