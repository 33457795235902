.hero{
    transition:all 1s;
    position: relative;
    padding-top: 0;
    background: url(../images/plus.svg) 95% 80% no-repeat, url(../images/a-1.svg) 104% 15% no-repeat,url(../images/a-1.svg) -5% 85% no-repeat,;

    .grid-half {
        align-items: center;
    }

    .title {
      margin-top: $size__spacing
    }

    .line {
        position: relative;
        padding-left: 4rem;

        &:before {
            content: '';
            display: inline-block;
            width: 3rem;
            height: 5px;
            background-color: $color__brand-secondary;
            position: absolute;
            top: 3em;
            margin-top: -5px;
            left: 0;
            transition: transform 0.4s;
            transition-delay: 1s;
            transform: scaleX(0);
            transform-origin: left;
        }

    }

    .person{
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      height: 100%;
      position: relative;
    }

    .person:before,.person:after{
      content: '';
      display: block;
      height: 100%;
      width: 182px;
      position: absolute;
      background-color:$color__brand-primary;
      left: 24%;
    }

    .person:after{
      background-color: $color__brand-secondary;
      width: 238px;
      left: calc(24% + 182px);
    }

    img{
        opacity:0;
        transition: opacity 1s;
        transition-delay: 1.5s;
        position: relative;
        z-index: 1;
        max-width: 100%;


    }

    .readmore {
        margin-top: $size__spacing/2;
    }

    .content-loaded & {
        .line:before {
            transform: scaleX(1);
        }

        img {
            opacity: 1
        }
    }

    @media screen and (max-width: $size__mobile) {
        padding-top: $size__spacing;

        background-size: 20%, 26%, 22%;
        background-position: 100% 80%, 120% 24%, -10% 74%;

        .content .title {
            margin-top: 0;
        }

        .person {
            padding-top: 2rem !important;
        }
        .person:before,.person:after {
            height: 20%;
            width: 200%;
            top: auto;
            left: -2rem;
        }
        .person:before {
            bottom:0;
        }
        .person:after {
            bottom: 20%;
        }
    }

}