[class*='grid-'] {
    display: grid;
    grid-auto-flow: row;

    .span-full {
        grid-column: 1/-1;
        margin-bottom: $size__spacing/2;
    }
}

[class*='grid-'] {
    display: grid;
}

.filters-button-group {
    grid-column: 1/-1
}
.grid-center {
    align-items: center;
}

.grid-half {
    grid-template-columns: 1fr 1fr;
}

.grid-third {
    grid-template-columns: repeat(3,1fr);
}

.service {
    display: grid;
    grid-template-columns: 1fr 2fr 3fr;
}

.grid-half-container {
    grid-template-columns:1fr repeat(2, minmax(auto,$size__grid-container/2)) 1fr;
    align-items: center;
    .content {
        padding-top: 0;
        padding-bottom: 0;
    }

    > *:first-child {
        grid-column: 2;
        position: relative;
    }
    .span-left {
        grid-column: 1/3;
    }

    .span-right {
        grid-column: 3/5;

        &+.content {
            grid-row: 1;
            grid-column: 2
        }

    }
}


.grid__services {
    display: grid;
}

.grid-sidebar {
    grid-template-columns: 7fr 2fr;
    grid-gap: $size__grid-gap;

} // grid_sidebar ends

.grid-gallery {
	grid-template-columns: repeat(4,1fr);
	grid-gap:  2rem;
}

.grid-blog {
    grid-template-columns: 100%;
}

.grid-with-gap {
    grid-gap: 4rem;
}

.static-content {
    // grid-template-columns: repeat(2,1fr);
    // display: grid;
    // grid-column-gap: 3rem;

    .line-after,p.lead {
        grid-column: 1/-1;
    }
    p.intro {
        grid-column: 1;
        max-width: 30em;
    }

    .content-left,.content-right {
        margin-top: $size__spacing/2;
    }

    p,ul,ol {
        max-width: 60em;

        grid-column: 2;
        p.lead {

        }
    }
}

.static-page {
    .page-content {
        display: grid;
        grid-template-columns: 2fr 6fr;
        grid-template-areas: "sidebar contentArea";
        margin-top: -18%;
        width: 100%;
        > div {
            grid-column: 2;
        }
    }

    .hero .main  {
        grid-template-columns: 2fr 6fr;
    }
}