/*  Structure */

$size__site-width : 1200px;
$size__tablet : 796px;
$size__large-tablet : 960px;
$size__small-tablet : 680px;
$size__header-width : 1700px;
$size__mobile : 860px;

$size__tablet : 23em;

$size__grid-container : 1330px;
$size__grid-main-column: 1330px/2;
$size__container: $size__grid-main-column*2;

$size__content-margin: 2rem;
$size__content-padding: 2rem;

$size__mobile-padding-right: 1rem;
$size__mobile-padding-left: 1rem;

$size__sidebar-padding: 2rem;

$size__grid-gap: 2rem;

$spacing : 5rem;
$size__spacing : $spacing;

$size__mobile-spacing: 3rem;

/*  Fonts     */
$font__main : 'Poppins';
$font__main-weight : 200;
$font__headings: 'Poppins';
$size__text-body:15px;
$size__text-body-lineheight:24px;

$size__text-body-small:11px;

//font-style font-variant font-weight font-size/line-height font-family
$font__main-shorthand : normal normal $font__main-weight 1rem/1.7  $font__main;

/*  Colors    */
$color__text-behind: #fcfcfc;
$color__text-behind: #f8f8f8;

// brand
$color__brand-primary: #5B5099;
$color__brand-secondary: #49B6AA;
$color__brand-gray: #F7F7F7;
$color__brand-darkgray: #CDCDCD;
$color__brand-white: #fff;

$box__shadow-default: 0 0 47px rgba($color__brand-secondary,0.37);
$box__shadow-default: 0 0 15px rgba($color__brand-primary,0.3);

$color__page-title: #121212;
$color__page-font: #121212;

// backgrounds
$color__background-main: #fff;
$color__background-footer: $color__brand-primary;
$color__inputs : #E7E7E7;
$color__background-odd: #F7F7F7;
$color__background-even: #FCFCFC;
$color__background-yellow: $color__brand-primary;
$color__background-side-menu : #fcfcfc;
$color__background-paralax: #fafafa;

// text
$color__text-screen :    #545E70;
$color__text-headings :  #545E70;
$color__text-headings-h1 : $color__text-headings;
$color__text-headings-h2 : $color__text-headings;
$color__text-headings-h3 : $color__text-headings;
$color__text-headings-h4 : $color__text-headings;
$color__text-headings-h5 : $color__text-headings;
$color__text-headings-h6 : $color__text-headings;
$color__blog-content : #545E70;

$color__text-white: #fff;

// Heading
$size__text-headings-h1 : 48px;
$size__lineheight-headings-h1: 48px;

$size__text-headings-h2 : 42px;
$size__lineheight-headings-h2: 42px;

$size__text-headings-h3 : 36px;
$size__lineheight-headings-h3: 45px;

$size__text-headings-h4 : 24px;
$size__lineheight-headings-h4: 32px;

$size__text-headings-h5 : 18px;
$size__lineheight-headings-h5: 28px;

$size__text-headings-h6 : 18px;
$size__lineheight-headings-h6: 28px;


$color__text-main : $color__brand-primary;
$color__text-light: #B3B3B3;
$color__text-footer : #fff;
$color__text-selection : #121212;
$color__text-light: #878787;

$color__border-navbar-main : #D5D5D5;
$color__border-categories : #D4D4D4;

// links
$color__link-main: $color__brand-primary;
$color__link-footer: $color__text-footer;
$color__link-telephone : #a7a7a7;
$color__link-telephone-main : #4f4f4f;

$color__link-alternative: #898989;

$color__background-table : #E8E8E8;
$color__even-table : #F2F2F2;
$color__border-table : #E7E7E7;
$color__border-bodyrow : #A7A7A7;

$color__text-table-heading : #222;

// forms
$color__border-input: #E5E5E6;
$color__border-input-focus: $color__brand-secondary;

// boxes
$color__border-box: #E5E5E6;
$color__background-box: #fff;