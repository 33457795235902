.main-navigation,
.footer-nav{
   ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
         display: inline-block;
      }
   }

   a{
      letter-spacing: 0.03em;
      position: relative;

      &.mobile{
         display: none;
      }
   }
}

.main-navigation{
    a{
        font-size: rem(18px);
        transition: all 1s;
        padding:5px 10px;
        position: relative;
        background-color: #fff;
        transition-delay: 0s;

        span {
            position: relative;
            text-transform: uppercase;
        }

        &:before {
            height: 100%;
            width: 100%;
            background-color: $color__brand-secondary;
            content: '';
            position: absolute;
            left: 0;
            z-index: 0;
            top: 0;
            transition: transform 1s;
            transform: scaleX(0);
            transform-origin: left bottom;
        }

        &:hover {

            transition-delay: 0.5s;
            color: $color__brand-white;

            &:before {
                transform: scaleX(1);
            }
        }

    }

    ul ul li {
        display: block;
        a{
            padding-left: 15px;
            display: block;
        }
    }

    li.active, li.child-active {
         a {
            font-weight: bold;
            border-bottom: 3px solid $color__brand-secondary;
        }
    }

    li ul {
        position: absolute;
        background-color: $color__brand-white;
        border: 1px solid $color__brand_gray;
        opacity: 0;
        transition: margin .3s,opacity .3s,right .3s, transform .3s;
        transform: matrix(0, 0, 0, 0, 0, 0);
        padding-top: 0.5em;
        padding-bottom: 0.5em;

        li a{
            font-size: 0.75em;
            padding: 1em;
            &:hover {
                background-color: $color__brand-secondary;
            }
        }
        li.active a {
            font-weight: bold;


        }
    }

    li:hover ul {
        opacity: 1;
        transform: matrix(1, 0, 0, 1, 0, 0);
        -webkit-transform-style: preserve-3d;
    }

    @media screen and (min-width: $size__mobile) {
        li+li {
            margin-left: 2rem;
        }
    }
    @media screen and (max-width: $size__mobile) {

        li.top-level > .link{
            margin-left: 0;
            margin-right: 0;
            font-weight: bold;

        }
        .sub-menu li a{
            padding-left: 1rem;
            display: block;
        }


        ul ul li.active {
            font-weight: bold;
            color: $color__brand-secondary;
        }

        li ul  {
            opacity: 1;
            transform: matrix(1, 0, 0, 1, 0, 0);
            position: static;
            border: none;

            li a {
                margin-left: 0 ;
            }
        }
    }

} // main navigation ends
