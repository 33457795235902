.table-wrapper {
	@media screen and (max-width: $size__mobile) {
		overflow: scroll;
	}

}
table {
	border-collapse: collapse;

	a {
		text-decoration: none;
	}

	&.courses {
		margin-top: $size__spacing/2;
		width: 100%;

		caption {
			text-align: left;
			margin-bottom: 1rem;
		}

		th {
			text-align: left;
			min-width: 8em
		}

		.date {
			min-width: 12em;
		}

		td,th {
			padding: 1.5rem 1rem;
			font-weight: normal;
		}

		th {
			font-weight: 600;
		}

		td:first-child {
			padding-left: 0;
		}

		thead {
			background-color: #F7FBFC;
			color: $color__brand-primary;
		}

		tbody {
			td {
				border-bottom: 1px solid darken($color__brand-gray,10%);
			}
			a {
				border-left: 5px solid $color__brand-primary;
				font-weight: 600;
				padding-left: $size__content-padding;
				display: block;
				padding-top: 0.2rem;
				padding-bottom: 0.2rem;
			}
		}

		tfoot td {
			text-align: center;
			padding-top: $size__spacing;
			a {
				font-weight: bold;
				color: $color__brand-primary;
				text-transform: uppercase;
				letter-spacing: 0.05em;
			}
		}
	}

	&.beginer {
		.price {
			color: $color__brand-primary;
			font-weight: 600;
		}
		a {
			border-color: $color__brand-primary;
		}
	}
}

.all-courses {
	tfoot {
		display: none;

	}
}