@import url('https://fonts.googleapis.com/css?family=Poppins:200,700,800&display=swap&subset=latin-ext');

::-webkit-scrollbar{
   width: .6rem;
   // background-color: $color__brand-secondary;
   border-left: 1px solid #fcfcfc;
}

::-webkit-scrollbar-thumb{
   background: $color__brand-primary;
   // height: 15rem;
}

@media screen and (min-width: $size__mobile - 1px) and (max-width: 1140px) {
    html {
     font-size: calc(0.875em + 0.25 * (100vw - 80em) / 40); /* 1 */
     font-size: -webkit-calc(87.5% + 0.25 * (100vw - 8000%) / 40); /* 2 */
     font-size: -ms-calc(0.875em + 0.25 * (100vw - 80em) / 40); /* 3 */
   }
}

@media screen and (max-width: $size__mobile ) {
    html {
     font-size: calc(0.975em + 0.25 * (100vw - 60em) / 40); /* 1 */
     font-size: -webkit-calc(97.5% + 0.25 * (100vw - 6000%) / 40); /* 2 */
     font-size: -ms-calc(0.975em + 0.25 * (100vw - 60em) / 40); /* 3 */
   }
}

html {
  min-height: 100%;
}

html,body {
  scroll-padding-top: 65px; /* set to the height of your header */
}

a {
  text-decoration: none;
}



body{
  font: $font__main-shorthand;
  color: $color__text-main;
  padding: 0;
  overflow-x: hidden;
  margin: 0;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  background:  url(../images/kolo.svg) 50% 0 no-repeat;

  &.toggled {
    overflow: hidden;
  }

  &.no-footer .site-footer {
    display: none;

  }
}

// .page-content {
//   margin-right: $size__content-margin;
//   margin-left: $size__content-margin;
// }

*{
  box-sizing: border-box;
}


*::selection {
	background: $color__text-selection;
	color: $color__brand-white;
}


a{
  transition: color 0.4s;

  h1 &,h2 &,h3 &,h4 &,h5 &,h6 &{
    text-decoration: none;
  }

}

figure{
  margin: 0;
  padding: 0;
}

a{
  color: currentColor;
}

a:hover{
 text-decoration: none;
}
