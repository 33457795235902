.slick-slider  {
	overflow: hidden;
	.slick-track {
		display: flex;
	}
}

.portfolio-carousel {
	position: relative;

}
.slick-dots {
	text-align: right;
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		display: inline-block;
		button {
			border: none;
			text-indent: -9999px;
			display: inline-block;
			width: 20px;
			height: 22px;
			transition: all 0.7s;
			background: url(../images/pager.svg) 0 0 no-repeat;
			background-size: contain;
			opacity: 0.5;
			&:focus {
				outline:none
			}
		}
		&:nth-child(2n) {
			transform: rotate(180deg);
		}


		&.slick-active button {
			transform: scale(1.1);
			opacity: 1;
		}
	}
}

.slick-arrow {
	display: inline-block;
	text-indent: -9999px;
	border: none;
	background-color: #fff;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	width: 30px;
	height: 30px;
	border: 1px solid $color__brand-primary;
	z-index: 9999;
	background-position: 50% 50%;
	background-size: 60%;
	background-repeat: no-repeat;
	background-image: url(../images/arrow.svg);
}
.slick-next {
	right: 20px;

}
.slick-prev {
	left: 20px;
	transform: rotate(180deg);
}
.arrows {
	margin-top: 1rem;
	padding-left: 6rem;
}

.arrow  {
	display: inline-block;
	vertical-align: middle;
	opacity: 0.8;
	transition:opacity 0.5s;
	cursor: pointer;

	&:hover {
		opacity: 1;
	}
	&.slick-disabled {
		opacity: 0.5;
	}
}