@keyframes scrollInsideBrowser {

    10% {
      transform: translateY(0%);
    }

    50% {
      transform: translateY(calc(-50% + 285px));
    }

    100% {
         transform: translateY(calc(-100% + 570px));
    }

}
@keyframes scrollInsideBrowserMobile {
   10% {
      transform: translateY(0%);
   }
   50% {
      transform: translateY(calc(-50% + 25vw));
   }
   100% {
      transform: translateY(calc(-100% + 45vw));
   }
}

@keyframes blurIn{
   from{
      filter: blur(2px);
      opacity: 1;
      transform: scale(1.1);
   }
   to{

      filter: blur(0px);
      transform: scale(1);
   }
}


@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes slideOut{
   from{
      height: 100vh;
      // opacity: 1;
   }
   99%{
      height: 100vh;
      opacity: 0;
   }
   to{
      height: 0;
      // opacity: 0;
   }
}

@keyframes fadeInFromBottom{
   from{
      top: -100vw;
      opacity: 0;
   }
   1%{
      top: 0;
   }
   to{
      opacity: 1;
   }
}


@keyframes underline{
   0%{
      height: 1px;
      width: 0;
   }
   50%{
      width: 100%;
      height: 1px;
   }
   100%{
      height: 10px;
      width: 100%;
   }
}
@keyframes underline-out{
   0%{
      height: 10px;
      width: 100%;
   }
   50%{
      width: 100%;
      height: 1px;
   }
   100%{
      height: 1px;
      width: 0;
   }
}

@keyframes hinge {
  0% {
    transform-origin: top left;
    animation-timing-function: ease-in-out;
    transform: translate3d(0, -300px, 0);
  }

  20%,
  60% {
    transform: rotate3d(0, 0, 1, 80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  40%,
  80% {
    transform: rotate3d(0, 0, 1, 60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  to {
    transform: translate3d(0, 0, 0);
    // opacity: 0;
  }
}

.hinge {
  animation-duration: 2s;
  animation-name: hinge;
  position: relative;
}


$bg-color: linear-gradient(25deg, #FFD401, #cf00f1);
$text-color: #fff;

.text-reveal {

  span {
    display: inline-block;
    transition: transform .6s cubic-bezier(.65,.02,.23,1);
    transform: translate(20%, 110%);
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateY(-50%);
      transition: transform .6s cubic-bezier(.65,.02,.23,1);
    }

    &.row {
      overflow: hidden;
      line-height: 0.9;
      display: block;
      transform: none;

      &:before {
        display: none;
      }
    }
  }

  .animate {
    transform: translate(0, 0);

    &:before {
      transform: translateY(100%);
    }
  }
}


.scroll-reveal {
   opacity: 0;
   transform: translateY(10px);
   transition: all 0.5s ease-in-out;
   &.is-revealed {
      opacity: 1;
      transform: translateY(0);
   }
}

%scrollInside{
   animation-duration: 5s;
   animation-name: scrollInsideBrowser;
   animation-timing-function: cubic-bezier(1.000, 0.005, 0.305, 1.000);
   animation-direction: alternate;
   animation-iteration-count: infinite;
   animation-fill-mode: none;
}


.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  // stroke-dasharray:7.22,14.43,0,0;
  animation: dash 5s linear forwards;
}




@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}


@keyframes cloud {
  0% {
    transform: translateY(-25%);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-25%);
  }
}

@keyframes cloudIn {
  0% {
    transform: translateY(-1000px);
  }
  100% {
    transform: translateY(0);
  }
}


@keyframes scaleIn {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scaleOut {
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(0);
    opacity: 0;
  }

}


.transition-fade {
    transition: .4s;
    opacity: 1;
    transition-delay: 5s;

    html.is-animating &{
        opacity: 0;
        transition-delay: 2.5s;
    }

    html.is-rendering & {
        transition-delay: 0s;
    }
}


.animate-slide {
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        transform: translateY(-100%);
        top: 0;
        background-color: #fff;
        transition: all 0.4s;
        transform-origin: bottom;
        transition-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
        transition-delay: 1s;
        z-index: 2;
        opacity: 1;
        left: 0;
    }


    html.is-rendering &:before{
        transition-delay: 5s;
        transform: translateY(0);
    }

    html.is-leaving &:before{
        transform: translateY(0);
        transition-delay: 1s;
    }
}

.animate-slideleft {
    position: relative;
    overflow: hidden;
    transform-origin: left;
    transition: transform 0.5s cubic-bezier(0.5, 0, 0.5, 1);
    transition-delay: 1.5s;

    & div > * {
      transition: opacity 0.5s;
      transition-delay: 2s;
    }

    html.is-rendering & {
        transform: scaleX(0);

        & div > * {
            transition-delay: 15s;
            opacity: 0;
        }
    }

    html.is-leaving & {
        transition-delay: 0.5s;
        transform: scaleX(0);

        & div > * {
            transition-delay: 0s;
            opacity: 0;
        }
    }

}

.animate-frombottom {
    html.is-leaving &{
        overflow: hidden;
        span {
            transform: translateY(-100%);
            transition-delay: 0s;
            opacity: 0;
        }
    }

    html.is-rendering & span{
        transition-delay: 3s;
        transform: translateY(-100%);
        opacity: 0;
    }

    span {
        display: block;
        transition: transform 0.6s, opacity 0.6s;
        transition-delay: 2s;
    }

}