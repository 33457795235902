label{
   // font-weight: bold;
   text-transform: uppercase;
   small {
       text-transform: none;

   }
}

input{
    &[type=text],
    &[type=password],
    &[type=phone],
    &[type=url],
    &[type=date],
    &[type=email]{
        @extend %input;
    }
}

textarea{
    @extend %input;
    width: 100%;
}