/*FONTS*/

.screen__reader-text{
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
}

hgroup {
    &.underline {
        text-align: center;
        margin-bottom: $size__spacing/2;

        h1,h2,h3,h4,h5,h6 {
            @extend %underline;
        }
    }

    h1,h2,h3,h4,h5,h6 {
        span {
            font-weight: 300;
        }
    }
}


h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{
    margin: 0;
    color: inherit;
    letter-spacing: 0.02em;
    font-weight: 800;

    span{
      font-weight: 300;
    }

    p+&,ul+&,ol+&,blockquote+&,address+& {
      margin-top: $size__spacing/2;
    }

    footer & {
        color: inherit;
    }

    &.underline {
        display: flex;
        flex-direction: column;
        &:after {
            content: '';
            display: inline-block;
            width: 2rem;
            margin-top: 0.5rem;
        }
        &:after {
            height: 4px;
            background-color: $color__brand-secondary;
        }
    }
    &.span {
        grid-column: 1/-1;
        text-align: center;
    }
    &.green {
      color: $color__brand-secondary;
    }
}

.page-title {
    color: $color__brand-primary;
    margin-bottom: 3rem;
}

.title {
    background-color: $color__brand-primary;
    color: $color__brand-white;
    display: inline-block;
    padding: 0.5rem 0;
    padding-right: 2rem;
    position: relative;
    margin-bottom: $size__spacing/2;

    &:before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        left: -999px;
        background-color: inherit;
        z-index: -1;
    }

}

strong {
    font-weight: 600
}

h1,.h1{
    font-size: rem($size__text-headings-h1);
    line-height: rem($size__lineheight-headings-h1);
}
h2,.h2{
    font-size: rem($size__text-headings-h2);
    line-height: rem($size__lineheight-headings-h2);
}
h3,.h3{
    font-size: rem($size__text-headings-h3);
    line-height: rem($size__lineheight-headings-h3);
}
h4,.h4{
    font-size: rem($size__text-headings-h4);
    line-height: rem($size__lineheight-headings-h4);
}

h5,.h5{
    font-size: rem($size__text-headings-h5);
    line-height: rem($size__lineheight-headings-h5);
}

h6,.h6{
    font-size: rem($size__text-headings-h6);
    line-height: rem($size__lineheight-headings-h6);
}

.post-title {
    color: $color__brand-primary;
    font-size: rem(24px);
}

.cookies p{
    font-size: 0.70rem;
    line-height: 1.5;
}

.contact_info div h5 {
    margin-top: 2rem;
}

@media screen and (max-width:$size__mobile){
    h1{
        font-size: rem(42px);
        line-height: rem(43px);
    }
    h2{
        font-size: rem(38px);
        line-height: rem(48px);
    }
    h3{
        font-size: rem(26px);
        line-height: rem(29px);
    }
    h4{
        font-size: rem(21px);
        line-height: rem(25px);
        letter-spacing: 0.03em;
    }
    h5{
        font-size: rem(19px);
        font-weight: bold;
        letter-spacing: 0.01em;
    }
    h6{
    }
}

p,ol,ul,address,dl{
   font-size: rem(15px);
   margin-bottom: 0;
   font-weight: 300;
}

p.large {
    font-size: rem(24px);
    &:first-child {
        margin-top: 0;
    }
}

p.subtitle {
    color: #878787;
    font-size: rem(18px);
    max-width: 40em;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 2rem;
    line-height: 1.4;
}

.lead,.strong-lead{
    font-size: rem(24px);
    line-height: rem(35px);
    max-width: 30em;
    // & + [class*='grid_'], & + div:not(.content-left) {
    //     margin-top: $size__spacing/2;
    // }
    // h1+& {
    //     margin-top: 2rem;
    // }
    margin-bottom: 1rem;
}

.strong-lead {
    font-weight: 500;
}


ol,ul{
    margin-top: $spacing/3;
    ul,ol{
      margin-top: 0;
   }
}

address{
    font-style: normal;
}


blockquote{
    font-size: rem(16px);
    &.testimonial {
        font-size: rem(18px);
        margin-top: 0;
        .person {
            display: flex;
            align-items: center;
        }
        cite {
            font-style: normal;
            margin-left: 3rem;
            margin-top: 2rem;
            line-height: 1.3;
            strong {
                font-weight: bold;
                display: block;
                font-size: rem(24px)
            }
        }
        p {
            font-size: rem(26px);
            position: relative;
            // padding-left: 3rem;
            margin-top: 0;
        }
    }
}

.contact-info {
    list-style: none;
    margin: 0;
    margin-top: 2rem;
    padding: 0;

    .name {
        font-weight: 600;
    }

}

.contact-list {
    @extend %contact-list;

    margin-top: 2rem;
    max-width: 600px;

    li {
        span {
            color: $color__brand-secondary;
            &:before,&:after {
                content: '|';
                margin: 5px;
            }
        }
        a {
            font-weight: 200;
        }
    }

    .contact-div &  {
        font-size: 1.3rem;
    }
}

.screenreader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
}


.plus-list {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        position: relative;
        align-items: baseline;
        padding-left: 20px;

        &:before {
            content: '';
            display: inline-block;
            background: url(../images/list-plus-marker.svg) no-repeat center center;
            background-size: contain;
            width: 10px;
            height: 10px;
            margin-right: 1rem;
            position: absolute;
            left: 0;
        }
    }
}