footer {
   a{
      text-decoration: none;
      position: relative;
   }
}

footer.site-footer{

    margin-top: auto;

    .h2 {
        line-height: 1.3;
    }

    .blue {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .h2+.h2 {
            margin-top: 2rem;
        }
    }

    ::selection {
        color: $color__brand-white;
        background: $color__brand-primary;
    }

    .contact  {
        padding: $size__spacing;

        svg {
            height: auto;
            margin-bottom: 2rem;
        }
    }

    @media screen and (max-width: $size__mobile) {
        .contact {
            padding-left: 0;
        }
        .blue {
            padding-right: 2rem;

            .h2 {
                font-size: rem(30px)
            }
        }
    }


}

.blog-footer {
    a {
        color: #fff;
    }
}