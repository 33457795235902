section {
	padding-top: $size__spacing;
	padding-bottom: $size__spacing;
}
.container {
   @extend %container;
}
.map-wrapper {
	min-height: 500px;
}

@keyframes showMe {
	0% {
		opacity:0;
	}
	1% {
		display: block;
	}
	100% {
		opacity:1;
	}
}

@keyframes hideMe {
	0% {
		opacity:1;
	}
	99% {
		opacity:0;
	}
	100% {
		display: none;
	}
}

.portfolio__link {
	display: none;

	&.show {
		display: block;
		animation: showMe 1s;
	}
}

.filters-button-group {
	margin-bottom: $size__spacing/2;
	button {
		border: 1px solid $color__brand-primary;
		font-size: rem(16px);
		background-color: $color__brand-white;
		color: currentColor;
		padding: 5px 10px;
		transition:all 0.5s;

		&:hover,&:active,&.active {
			background-color: $color__brand-primary;
			color: $color__brand-white;
		}
	}
}
.gray {
	background-color: $color__brand-gray;
	&.third-white {
		position: relative;
		&:before {
			content: '';
			background-color: $color__brand-white;
			position: absolute;
			left: 0;
			right: 66%;
			top: 0;
			bottom: 0;
			display: block;
			z-index: 0;
		}
	}
}

.grid-half-container {
	[class*='span'],.content{
		position: relative;
	}

	.content {
        padding: $size__spacing/2;

    }

}

.content {
	position: relative;

	.readmore {
		margin-top: $size__spacing/2;
	}
}



.blue {
	background-color: $color__brand-primary;
	color: $color__brand-white;
}

.flex-container {
	display: flex;

	.content-block {
		max-width: 60em;

	}
}

.grid-third {
	.span-left,.span-right {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            bottom: 0;
            background-color: inherit;
            z-index: -1;
        }
    }

    .span-right {
        grid-column: 2/4;

        // &:before {
        //     left: 0;
        //     right: -9999px;
        // }
    }

    .span-left {
        grid-column: 1/3;

        &:before {
            right: 0;
            left: -9999px;
        }
    }
}

.testimonials {
	text-align: center;
	img {
		margin-bottom: 2rem;
	}
	blockquote p{
		max-width: 45em;
		margin-left: auto;
		margin-right: auto;

	}
	.testimonial__title {
		text-transform: uppercase;
		font-size: rem(30px);
		@include underline($color__brand-secondary);
	}
}

.icon-box {
	padding-right: $size__spacing/2;
}



.core_loader-overlay {
	position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 99;
    max-width: 0%;
    background: #fff;
    opacity: 0;
    pointer-events: none;
    transition: opacity 1s .3s,max-width 0s 1.3s;
    transform-origin: center bottom;
    display: flex;
    justify-content: center;
    align-items: center;

    &.-show {
    	max-width: 100%;
	    pointer-events: auto;
	    opacity: 1;
	    transition-delay: .3s,0s;
	    transition-duration: .3s,0s;
    }

    &.-front {
    	z-index: 9999;
    }

    .loader_image {
    	max-width: 200px;
    }
}
.services {
	background:  url(../images/kolo-left.svg) 0 90% no-repeat;

}
.services-list {
	background:  url(../images/services-bg.svg) 100% 50% no-repeat;
	background-size: initial;
}
.service {
	padding-left: $size__spacing;
	&+& {
		margin-top: $size__spacing/2;
	}

	figure {
		min-width: 120px;
		max-width: 150px;
		margin-right: $size__spacing;
		text-align: center;

	}
	h4 {
		text-transform: uppercase;
		flex-grow: 1;
	}
	div {
		flex-grow: 3;
	}

}

.portfolio-carousel {
	padding-left: $size__spacing/2;
	padding-right: $size__spacing/2;

	.slick-dots {
		margin-top: 2rem;
	}

	.portfolio__link {
		position: relative;
		display: inline-block;
		margin-right: 4rem;

		&:before {
			background-color: $color__brand-primary;
			opacity: 1;
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			// transition: opacity 1s;
		}
		img {
			display: block;
			// mix-blend-mode: multiply;
			mix-blend-mode: luminosity;
		}
		&:hover {
			&:before {
				opacity: 0;
			}
			img {

				mix-blend-mode: normal;
			}
		}

	}
}

// Version: 2.9.2

.tns-outer {
  [hidden] { display: none !important; }
  [aria-controls], [data-action] { cursor: pointer; }
}
.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
  > .tns-item {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.tns-horizontal {
  &.tns-subpixel {
    white-space: nowrap;
    > .tns-item {
      display: inline-block;
      vertical-align: top;
      white-space: normal;
    }
  }
  &.tns-no-subpixel {
    &:after {
      content: '';
      display: table;
      clear: both;
    }
    > .tns-item {
      float: left;
    }
  }
  &.tns-carousel {
    &.tns-no-subpixel {
      > .tns-item {
        margin-right: -100%;
      }
    }
  }
}
.tns-no-calc {
  position: relative;
  left: 0;
}
.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px; // make sure slider container is visible
  // overflow: hidden;
  > .tns-item {
    position: absolute;
    left: -100%;
    -webkit-transition: transform 0s, opacity 0s;
    -moz-transition: transform 0s, opacity 0s;
    transition: transform 0s, opacity 0s;
  }
  > .tns-slide-active {
    position: relative;
    left: auto !important;
  }
  > .tns-moving {
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    transition: all 0.25s;
  }
}
.tns-autowidth { display: inline-block; }
.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
  &.tns-complete { opacity: 1; }
}
.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}
.tns-ovh { overflow: hidden; }
.tns-visually-hidden { position: absolute; left: -10000em; }
.tns-transparent { opacity: 0; visibility: hidden; }

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}
.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}


// *** Fix a viewport issue in initialization
.tns-vpfix {
  white-space: nowrap;
  > div, > li {
    display: inline-block;
  }
}

// *** Detecting browser capability ***
$width: 310px;
$height: 10px;
$count: 70;
$perpage: 3;

.tns-t {
  &-subp2 {
    margin: 0 auto;
    width: $width;
    position: relative;
    height: $height;
    overflow: hidden;
  }
  &-ct {
    width: (100% * $count / $perpage);
    width: -webkit-calc(100% * #{$count} / #{$perpage});
    width: -moz-calc(100% * #{$count} / #{$perpage});
    width: calc(100% * #{$count} / #{$perpage});
    position: absolute;
    right: 0;
    &:after {
      content: '';
      display: table;
      clear: both;
    }
    > div {
      width: (100% / $count);
      width: -webkit-calc(100% / #{$count});
      width: -moz-calc(100% / #{$count});
      width: calc(100% / #{$count});
      height: $height;
      float: left;
    }
  }
}

.tns-outer {
    position: relative;
    margin-left: 4rem;
}

.tns-nav {
    display: flex;
    align-items: center;
    // justify-content: center;
    width: 30px;
    margin-right: 1rem;
    button {
        border: none;
        background-color: #E7E7E7;
        width: 12px;
        height: 12px;
        margin-bottom: 10px;
        border-radius: 50% ;
        transition:all 0.7s ;
        &.tns-nav-active {
            width: 16px;
            height: 16px;
            background-color: $color__link-main;
        }
        &:focus,&:active {
            outline: none;
        }
    }
}
.company-meta {
	display: block;

}

.next-project {
	text-align: center;
	padding-top: 0;
}