@keyframes bounce {
   0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
   40% {transform: translateY(-30px);}
   60% {transform: translateY(-15px);}
}

@keyframes fade {
   from {
      opacity:0;
   }
   to {
      opacity: 1;
   }
}

.headroom {
    will-change: transform;
    transition: transform 200ms linear;
    position: fixed;
}

.headroom--pinned {
    transform: translateY(0%);
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    background-color: #fff;
}

.headroom--unpinned {
    background-color: #fff;
    transform: translateY(-100%);

    &.toggled {
        transform: translateY(0);
    }
}

.headroom--not-top {
    background-color: #fff;

}

.headroom--top {
    box-shadow: none;
    background-color: transparent;
}


.site-header svg #plus {
   animation-duration: 2s;
   animation-fill-mode: both;
   animation-iteration-count: infinite;
   animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);

   .is-changing & {
      animation-name: fade;
   }

}

.site-header{
   width: 100%;
   transition: all .7s ease-out;
   position: fixed;
   top: 0;
   z-index: 999;

   .head{
      margin-right: auto;
      margin-left: auto;
   }

   a{
      text-decoration: none;
   }

   h1.logo{
      transition:all 0.4s;
      padding-bottom: 1rem;

      svg {
         max-width: 310px;
         height: auto;
      }
   }

   .scrolled & {
      h1.logo {
         margin-top: 0;
      }
   }
}

.site-header {
   svg  {
      transition:all 0.5s;

      path {
         transition: opacity 0.5s;
      }

      path {
         opacity: 1;
      }
   }
}

@media screen and (max-width: $size__mobile  + 1px){
   @import 'header/mobile';
}
@media screen and (min-width:$size__mobile ){
   @import 'header/desktop';
}