[class*=grid__]{
   img{
      max-width: 100%;
   }
   img{

      &.rellaxed-up,
      &.rellaxed-down{
         position: absolute;
         max-width:20vw;
         z-index: 0;
      }
   }
}
img {
   max-width: 100%;
   &.fr-fil {
       float: left;
         margin-right: 1rem;
         margin-bottom: 1rem;

   }

}

figure {
   &.logo {
      margin-bottom: 2rem;
   }
}

.svglogo {
   max-width: 300px;
}

.portfolio-image {
   display: block;

}

.image-with-border {
   position: relative;

   img {
      margin-left: auto;
      margin-right: auto;
      position: relative;

   }
   &:before {
      content: '';
      display: inline-block;
      position: absolute;
      background-color: $color__brand-primary;
      width: 50%;
      top: -$size__spacing;
      bottom: 0;
      left: 0;
   }
}