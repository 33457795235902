.popup {
	position: fixed;
	z-index: 9999;
	top: 10%;
	left: 50%;
	right: 0;
	bottom: 10%;
	transform: translateX(-50%);
	display: none;
	overflow: scroll;
	padding: 50px 20px;
	background-color: $color__brand-white;
	max-width: 700px;
	border: 4px solid $color__brand-primary;
	box-shadow: 0 0 20px rgba($color__brand-primary,0.6);
	max-height: 60vh;

	.modal-header {
		position: relative;z-index: 9;
	}

	.popup-content {
	}
}